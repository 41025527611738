import { createRouter, createWebHistory } from "vue-router";
import HomePage from "../views/Home.vue";
import VerifyEmail from '../views/VerifyEmail.vue';

const routes = [
  {
    path: "/",
    name: "HomePage",
    component: HomePage,
    meta: {
      hideNavigation: true  // 添加这个元数据
    }
  },
  {
    path: '/verify-email/:token',
    name: 'VerifyEmail',
    component: VerifyEmail,
    meta: {
      hideNavigation: true  // 隐藏导航栏
    }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;