<template>
  <div class="verify-container">
    <!-- 背景图片 -->
    <img src="@/assets/background.png" alt="Background" class="background-image" />

    <div class="verify-box">
      <!-- 根据验证状态显示不同内容 -->
      <template v-if="verificationStatus === 'pending'">
        <h1>Verifying...</h1>
        <p>Please wait while we verify your email.</p>
      </template>

      <template v-else-if="verificationStatus === 'success'">
        <h1>Verification Successful!</h1>
        <p>Your email has been successfully verified.</p>
        <p class="additional-info">The test application will send you a link as soon as possible.</p>
        <button class="back-button" @click="goHome">Back to Home</button>
      </template>

      <template v-else>
        <h1>Verification Failed</h1>
        <p>{{ errorMessage }}</p>
        <button class="back-button" @click="goHome">Back to Home</button>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VerifyEmail',
  data() {
    return {
      verificationStatus: 'pending', // 'pending', 'success', 'error'
      errorMessage: ''
    }
  },
  async created() {
    // 从 URL 获取验证 token
    const token = this.$route.params.token
    
    try {
      // 修改为匹配后端的 API 路径
      const response = await fetch(`https://gamlifyweb-gebjgnchc0a8hba4.eastus2-01.azurewebsites.net/api/verify-email/${token}`)
      
      if (!response.ok) {
        const errorData = await response.json()
        throw new Error(errorData.message || 'Verification failed')
      }
      
      await response.json() // 只需要知道请求成功
      this.verificationStatus = 'success'
    } catch (error) {
      console.error('Verification error:', error)
      this.verificationStatus = 'error'
      this.errorMessage = error.message || 'Verification failed. Please try again.'
    }
  },
  methods: {
    goHome() {
      this.$router.push('/');
    }
  }
};
</script>

<style scoped>
.verify-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.verify-box {
  width: 400px;
  padding: 40px;
  border-radius: 20px;
  text-align: center;
  background: rgba(107, 75, 255, 0.15);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  animation: fadeIn 0.5s ease-out;
}

h1 {
  color: white;
  font-size: 28px;
  margin-bottom: 16px;
  font-weight: 600;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

p {
  color: rgba(255, 255, 255, 0.9);
  font-size: 16px;
  margin-bottom: 15px;
  line-height: 1.5;
}

.additional-info {
  color: rgba(255, 255, 255, 0.9);
  font-size: 14px;
  margin-bottom: 30px;
  font-style: italic;
}

.back-button {
  width: 134px;
  height: 43px;
  border: none;
  background: linear-gradient(90deg, #3A55FF 0%, #5629F7 100%);
  color: white;
  font-size: 16px;
  cursor: pointer;
  border-radius: 6px;
  position: relative;
  box-shadow: 0 0 15px rgba(86, 41, 247, 0.3);
  transition: all 0.3s ease;
}

.back-button:hover {
  background: linear-gradient(90deg, #4B63FF 0%, #6A3FFF 100%);
  transform: translateY(-1px);
  box-shadow: 0 4px 20px rgba(86, 41, 247, 0.4);
}

.back-button:active {
  transform: translateY(1px);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* 适配移动设备 */
@media (max-width: 480px) {
  .verify-box {
    width: 90%;
    padding: 30px 20px;
    margin: 0 20px;
  }

  h1 {
    font-size: 24px;
  }

  p {
    font-size: 14px;
  }

  .additional-info {
    font-size: 13px;
  }

  .back-button {
    width: 120px;
    height: 40px;
    font-size: 14px;
  }
}
</style>
